import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { CodeBlock } from "../commonComponents"


export default function FeedbackAPIDocs() {
  const json = {
    "result": "success"
}

  return (
    <Layout
      keywords="Feedback API guide"
      title="Send AI feedback to IrisAgent's API"
      description="Documentation on how to send user or agent feedback on IrisGPT responses via our APIs"
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/feedback-api-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Feedback API Guide</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>Feedback API</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>
                    <h1>Feedback API Documentation</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    You can send user or agent feedback on IrisGPT responses via our APIs. This feedback helps IrisAgent improve the quality of its responses over time.
                    <br/>
                    <br/>
                    <h2>
                    API Guide
                    </h2>
                    <br/>
                    Below is a <span style={{"font-family": "Consolas"}}>curl</span> command to call our API:
                    <CodeBlock language="javascript" value={`
curl -X POST --location "https://api1.irisagent.com/v1/feedback" \\
    -H "Content-Type: application/json" \\
    -H "Authorization: Bearer Replace_me_business_id" \\
    -d '{
            "conversationId": "12345",
            "feedback": "Like"
        }'
          `}>
</CodeBlock>
<br/>
The API inputs are as follows,
<br/><br/>
<table style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
    <tr>
        <th>Field</th>
        <th>Description</th>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>conversationId</span></td>
        <td>[Required] Unique ticket or chat id for whom feedback is being provided. Please note that this conversationId should match the conversationId used to get the response from <a href="../irisgpt-api-docs" target="_blank">IrisGPT API</a>.</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>feedback</span></td>
        <td>[Required] Feedback on the AI response. It should be either "Like" or "Dislike".</td>
    </tr>
    <tr>
        <td><span style={{"font-family": "Consolas"}}>Replace_me_business_id</span></td>
        <td>This is the ID provided by the IrisAgent team</td>
    </tr>
</table>
<br/><br/>
A sample output of the API:
<br/><br/>
<CodeBlock language="json" value={JSON.stringify(json, null, 2)}>
</CodeBlock>

                    </div>
                    </div>
                    </section>

</Layout>
  )
}